import { createWebHistory, createRouter } from 'vue-router'

import Login from "../Pages/Auth/Login.vue"
import Layout from "../Pages/Layout/Layout"
import Dashboard from "../Pages/Dashboard/Dashborad"
import Department from "../Pages/Settings/Department/Department.vue"
import Designation from "../Pages/Settings/Designation/Designation.vue"
import Category from "../Pages/Settings/Category/Category.vue"
import Bank from "../Pages/Settings/Bank/Bank.vue"
import Account from "../Pages/Settings/Account/Account.vue"
import Member from "../Pages/Member/Member.vue"
import MemberAdd from "../Pages/Member/Add.vue"
import MemberEdit from "../Pages/Member/Edit.vue"
import MemberView from "../Pages/Member/View.vue"
import MemberViewSearch from "../Pages/Member/ViewBySearch.vue"
import DateWiseCollection from "../Pages/Report/DateWiseCollection.vue";
import DateWiseExpense from "../Pages/Report/DateWiseExpense.vue";
import DateWiseCollectionVsExpense from "../Pages/Report/DateWiseCollectionVsExpense.vue";
import Income from "../Pages/Accounting/Income/List.vue";
import IncomeAdd from "../Pages/Accounting/Income/Add.vue";
import IncomeEdit from "../Pages/Accounting/Income/Edit.vue";
import Expenditure from "../Pages/Accounting/Expenditure/List.vue";
import ExpenditureAdd from "../Pages/Accounting/Expenditure/Add.vue";
import ExpenditureEdit from "../Pages/Accounting/Expenditure/Edit.vue";

const routes = [
    {
        name: "login",
        path: "/auth/login",
        component: Login,
        beforeEnter: authCheck,
    },
    {
        name: "layout",
        path: "/",
        component: Layout,
        beforeEnter: authRequestCheck,
        children: [
            { path: "/", name: "dashboard", component: Dashboard},
            { path: "/department", name: "department", component: Department},
            { path: "/designation", name: "designation", component: Designation},
            { path: "/category", name: "category", component: Category},
            { path: "/bank", name: "bank", component: Bank},
            { path: "/account", name: "account", component: Account},
            { path: "/member", name: "member", component: Member},
            { path: "/member/add", name: "memberAdd", component: MemberAdd},
            { path: "/member/edit/:id", name: "memberEdit", component: MemberEdit},
            { path: "/member/view/:id", name: "memberView", component: MemberView},
            { path: "/member/view/search", name: "memberViewSearch", component: MemberViewSearch},
            { path: "report/dateWiseCollection", name: "dateWiseCollection", component: DateWiseCollection},
            { path: "report/dateWiseExpense", name: "dateWiseExpense", component: DateWiseExpense},
            { path: "report/dateWiseCollectionVsExpense", name: "dateWiseCollectionVsExpense", component: DateWiseCollectionVsExpense},
            { path: "account/income", name: "income", component: Income},
            { path: "account/income/add", name: "incomeAdd", component: IncomeAdd},
            { path: "account/income/edit/:id", name: "incomeEdit", component: IncomeEdit},
            { path: "account/expense", name: "expense", component: Expenditure},
            { path: "account/expense/add", name: "expenseAdd", component: ExpenditureAdd},
            { path: "account/expense/edit/:id", name: "expenseEdit", component: ExpenditureEdit},
        ]
    }
]

function authCheck(to, from, next) {
    let token = localStorage.getItem('samity_access_token');
    if (token !== undefined && token != null) {
        next('/');
    } else {
        next();
    }
}
function authRequestCheck(to, from, next) {
    let token = localStorage.getItem('samity_access_token');
    if (token === undefined || token == null) {
        next('/auth/login');
    } else {
        next();
    }
}

const router = createRouter({
    history: createWebHistory(),
    routes,
})
export default router

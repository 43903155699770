<template>
    <div>
        <Breadcrumb section-name="Member" :breadcrumb="breadCrumb"></Breadcrumb>
        <div class="col-12">
            <div class="card mb-0">
                <div class="card-body" :class="{'data-loading': dataLoading}">
                    <form @submit.prevent="manage">
                        <div class="fixed-card-height">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="card border-top border-0 border-4 border-primary">
                                        <div class="card-body p-4">
                                            <div class="card-title d-flex align-items-center">
                                                <h5 class="mb-0 text-primary">System Information</h5>
                                            </div>
                                            <hr>
                                            <div class="row g-3">
                                                <div class="col-md-12 form-group">
                                                    <label class="form-label">Member Type<span class="text-danger">*</span></label>
                                                    <div class="d-flex align-items-center">
                                                        <div class="form-check me-4">
                                                            <input class="form-check-input" v-model="addEditParam.type" @change="getNextId()" value="member" type="radio" name="type" id="member">
                                                            <label class="form-check-label" for="member">Member</label>
                                                        </div>
                                                        <div class="form-check me-4">
                                                            <input class="form-check-input" v-model="addEditParam.type" @change="getNextId()" value="donor" type="radio" name="type" id="donor">
                                                            <label class="form-check-label" for="donor">Donor</label>
                                                        </div>
                                                        <div class="form-check me-4">
                                                            <input class="form-check-input" v-model="addEditParam.type" @change="getNextId()" value="suppliers" type="radio" name="type" id="suppliers">
                                                            <label class="form-check-label" for="suppliers">Suppliers</label>
                                                        </div>
                                                        <div class="form-check me-4">
                                                            <input class="form-check-input" v-model="addEditParam.type" @change="getNextId()" value="others" type="radio" name="type" id="others">
                                                            <label class="form-check-label" for="other">Others</label>
                                                        </div>
                                                    </div>
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="form-label">ID Number<span class="text-danger">*</span></label>
                                                    <input type="text" disabled class="form-control" name="id_number" v-model="addEditParam.id_number">
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="form-label">RI Member No<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" autocomplete="new-email" name="ri_member_no" v-model="addEditParam.ri_member_no">
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-12 form-group">
                                                    <label class="form-label">Category<span class="text-danger">*</span></label>
                                                    <select class="form-select" v-model="addEditParam.category" name="gender">
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                        <option value="high">High</option>
                                                        <option value="medium">Medium</option>
                                                        <option value="low">Low</option>
                                                    </select>
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-top border-0 border-4 border-primary">
                                        <div class="card-body p-4">
                                            <div class="card-title d-flex align-items-center">
                                                <h5 class="mb-0 text-primary">Contact Information</h5>
                                            </div>
                                            <hr>
                                            <div class="row g-3">
                                                <div class="col-md-6 form-group">
                                                    <label class="form-label">Contact Number<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" name="contact_number" v-model="addEditParam.contact_number">
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="form-label">Emergency Number<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" autocomplete="new-email" name="emergency_contact" v-model="addEditParam.emergency_contact">
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="form-label">Whatsapp Number</label>
                                                    <input type="text" class="form-control" autocomplete="new-email" name="whatsapp_number" v-model="addEditParam.whatsapp_number">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="form-label">Email</label>
                                                    <input type="text" class="form-control" autocomplete="new-email" name="facebook_link" v-model="addEditParam.email_address">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="form-label">Facebook Link</label>
                                                    <input type="text" class="form-control" autocomplete="new-email" name="facebook_link" v-model="addEditParam.facebook_link">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="form-label">Instagram Link</label>
                                                    <input type="text" class="form-control" autocomplete="new-email" name="instagram_link" v-model="addEditParam.instagram_link">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-top border-0 border-4 border-primary">
                                        <div class="card-body p-4">
                                            <div class="card-title d-flex align-items-center">
                                                <h5 class="mb-0 text-primary">Address Information</h5>
                                            </div>
                                            <hr>
                                            <div class="row g-3">
                                                <div class="col-md-12 form-group">
                                                    <label class="form-label">Present Address<span class="text-danger">*</span></label>
                                                    <textarea type="text" class="form-control" name="present_address" v-model="addEditParam.present_address"></textarea>
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-12 form-group">
                                                    <label class="form-label">Permanent Address</label>
                                                    <textarea type="text" class="form-control" name="permanent_address" v-model="addEditParam.permanent_address"></textarea>
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-8">
                                    <div class="card border-top border-0 border-4 border-primary">
                                        <div class="card-body p-4">
                                            <div class="card-title d-flex align-items-center">
                                                <h5 class="mb-0 text-primary">Personal Information</h5>
                                            </div>
                                            <hr>
                                            <div class="row g-3">
                                                <div class="col-md-12">
                                                    <div class="row mb-4">
                                                        <div class="col-sm-3">
                                                            <template v-if="!photo">
                                                                <input @change="addPhoto" id="fancy-file-upload" type="file"
                                                                       name="files" accept=".jpg, .png, image/jpeg, image/png"
                                                                       class="d-none">
                                                                <label for="fancy-file-upload" class="file-upload avatar">Photo</label>
                                                            </template>
                                                            <template v-if="photo">
                                                                <div class="position-relative w-content">
                                                                    <img class="avatar" :src="photo" alt="">
                                                                    <div class="remove-avatar bg-danger" @click="removePhoto">
                                                                        <i class="bx bx-trash"></i>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </div>
                                                        <div class="col-sm-9">
                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <div class="mb-3 form-group">
                                                                        <label class="form-label">Name<span class="text-danger">*</span></label>
                                                                        <input type="text" class="form-control" name="name" v-model="addEditParam.name">
                                                                        <small class="invalid-feedback text-danger"></small>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class="mb-3 form-group">
                                                                        <label class="form-label">Father's Name<span class="text-danger">*</span></label>
                                                                        <input type="text" class="form-control" name="father_name" v-model="addEditParam.father_name">
                                                                        <small class="invalid-feedback text-danger"></small>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class="mb-3 form-group">
                                                                        <label class="form-label">Mother's Name<span class="text-danger">*</span></label>
                                                                        <input type="text" class="form-control" name="mother_name" v-model="addEditParam.mother_name">
                                                                        <small class="invalid-feedback text-danger"></small>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class="mb-3 form-group">
                                                                        <label class="form-label">Spouse Name</label>
                                                                        <input type="text" class="form-control" name="phone" v-model="addEditParam.spouse_name">
                                                                        <small class="invalid-feedback text-danger"></small>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-5">
                                                            <template v-if="!signature">
                                                                <input @change="addSignature" id="fancy-sig-upload" type="file"
                                                                       name="files" accept=".jpg, .png, image/jpeg, image/png"
                                                                       class="d-none">
                                                                <label for="fancy-sig-upload" class="file-upload signature">
                                                                    Signature
                                                                </label>
                                                            </template>
                                                            <template v-if="signature">
                                                                <div class="position-relative">
                                                                    <img class="signature" :src="signature" alt="">
                                                                    <div class="remove-sig bg-danger" @click="removeSignature">
                                                                        <i class="bx bx-trash"></i>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </div>
                                                        <div class="col-sm-7">
                                                            <div class="row">
                                                                <div class="col-sm-12">
                                                                    <div class="mb-3 form-group">
                                                                        <label class="form-label">Number of Child</label>
                                                                        <input type="text" class="form-control" name="phone" v-model="addEditParam.no_of_child">
                                                                        <small class="invalid-feedback text-danger"></small>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class="mb-3 form-group">
                                                                        <label class="form-label">Number of Son</label>
                                                                        <input type="text" class="form-control" name="phone" v-model="addEditParam.no_of_son">
                                                                        <small class="invalid-feedback text-danger"></small>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class="mb-3 form-group">
                                                                        <label class="form-label">Number of Daughter</label>
                                                                        <input type="text" class="form-control" name="phone" v-model="addEditParam.no_of_daughter">
                                                                        <small class="invalid-feedback text-danger"></small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4 form-group">
                                                    <label class="form-label">Nationality<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" name="mother_name" v-model="addEditParam.nationality">
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label">NID Number<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" name="mother_name" v-model="addEditParam.nid_number">
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label">Gender<span class="text-danger">*</span></label>
                                                    <select class="form-select" v-model="addEditParam.gender" name="gender">
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                    </select>
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label">Religion<span class="text-danger">*</span></label>
                                                    <select class="form-select" v-model="addEditParam.religion" name="religion">
                                                        <option value="islam">Islam</option>
                                                        <option value="hinduism">Hinduism</option>
                                                        <option value="buddhism">Buddhism</option>
                                                        <option value="christianity">Christianity</option>
                                                    </select>
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>

                                                <div class="col-md-4 form-group">
                                                    <label class="form-label">Marital Status<span class="text-danger">*</span></label>
                                                    <select class="form-select" v-model="addEditParam.marital_status" name="marital_status">
                                                        <option value="single">Single</option>
                                                        <option value="married">Married</option>
                                                        <option value="divorce">Divorced</option>
                                                    </select>
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label">Blood Group</label>
                                                    <select class="form-select" v-model="addEditParam.blood_group" name="blood_group">
                                                        <option value="a+">A+</option>
                                                        <option value="a-">A-</option>
                                                        <option value="b+">B+</option>
                                                        <option value="b-">B-</option>
                                                        <option value="ab+">AB+</option>
                                                        <option value="ab-">AB-</option>
                                                        <option value="o+">O+</option>
                                                        <option value="o-">O-</option>
                                                    </select>
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label">Hobby</label>
                                                    <input type="text" class="form-control" name="hobby" v-model="addEditParam.hobby">
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-4 form-group position-relative">
                                                    <label class="form-label">Date of Birth<span class="text-danger">*</span></label>
                                                    <flat-pickr class="form-control" v-model="addEditParam.date_of_birth" name="date_of_birth"
                                                                :config="{dateFormat: 'Y-m-d', altInput: true, altFormat:'d/m/Y'}"/>
                                                    <i class="bx bx-calendar calendar-icon"></i>
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>


                                                <div class="col-md-4 form-group">
                                                    <label class="form-label">Profession</label>
                                                    <input type="text" class="form-control" name="profession" v-model="addEditParam.profession">
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label">Organization</label>
                                                    <input type="text" class="form-control" name="organization" v-model="addEditParam.organization">
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label">Last Education</label>
                                                    <input type="text" class="form-control" name="last_education" v-model="addEditParam.last_education">
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label">Remuneration</label>
                                                    <input type="text" class="form-control" name="remuneration" v-model="addEditParam.remuneration">
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>

                                                <div class="col-md-4 form-group">
                                                    <label class="form-label">Member</label>
                                                    <select class="form-select" v-model="addEditParam.member" name="member">
                                                        <option value="front">Front</option>
                                                        <option value="back">Back</option>
                                                    </select>
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label">Department<span class="text-danger">*</span></label>
                                                    <select class="form-select" v-model="addEditParam.department_id" name="department_id">
                                                        <option value="">Select Department</option>
                                                        <option v-for="d in departments" :value="d.id">{{d.name}}</option>
                                                    </select>
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label">Designation<span class="text-danger">*</span></label>
                                                    <select class="form-select" v-model="addEditParam.designation_id" name="designation_id">
                                                        <option value="">Select Designation</option>
                                                        <option v-for="d in designations" :value="d.id">{{d.name}}</option>
                                                    </select>
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>

                                                <div class="col-md-4 form-group position-relative">
                                                    <label class="form-label">Joining Date<span class="text-danger">*</span></label>
                                                    <flat-pickr class="form-control" v-model="addEditParam.joining_date" name="joining_date"
                                                                :config="{dateFormat: 'Y-m-d', altInput: true, altFormat:'d/m/Y'}"/>
                                                    <i class="bx bx-calendar calendar-icon"></i>
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label">Reference</label>
                                                    <input type="text" class="form-control" name="last_education" v-model="addEditParam.references">
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label">Other Organization</label>
                                                    <input type="text" class="form-control" name="remuneration" v-model="addEditParam.others_organization">
                                                    <small class="invalid-feedback text-danger"></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="card border-top border-0 border-4 border-primary">
                                        <div class="card-body p-4">
                                            <div class="card-title d-flex align-items-center">
                                                <h5 class="mb-0 text-primary">Education Information</h5>
                                            </div>
                                            <hr>
                                            <div class="g-3">
                                                <div class="row mb-3" v-for="(e, index) of addEditParam.education">
                                                    <div class="col form-group" >
                                                        <label :for="'education.'+index+'.exam_name'" class="form-label">Exam Name<span class="text-danger">*</span></label>
                                                        <select class="form-select" :name="'education.'+index+'.exam_name'" :id="'education.'+index+'.exam_name'"  v-model="e.exam_name">
                                                            <option value="ssc">SSC</option>
                                                            <option value="hsc">HSC</option>
                                                            <option value="graduation">Graduation</option>
                                                            <option value="post graduation">Post Graduation</option>
                                                            <option value="mba">MBA</option>
                                                            <option value="doctorate">Doctorate</option>
                                                        </select>
                                                        <small class="invalid-feedback text-danger"></small>
                                                    </div>
                                                    <div class="col form-group">
                                                        <label :for="'education.'+index+'.group'" class="form-label">Group/Subject<span class="text-danger">*</span></label>
                                                        <input type="text" v-model="e.group" :name="'education.'+index+'.group'"
                                                               class="form-control" :id="'education.'+index+'.group'" placeholder="Group/Subject">
                                                        <small class="invalid-feedback text-danger"></small>
                                                    </div>
                                                    <div class="col form-group">
                                                        <label :for="'education.'+index+'.board'" class="form-label">Board/University<span class="text-danger">*</span></label>
                                                        <input type="text" v-model="e.board" :name="'education.'+index+'.board'"
                                                               class="form-control" :id="'education.'+index+'.board'" placeholder="Board/University">
                                                        <small class="invalid-feedback text-danger"></small>
                                                    </div>
                                                    <div class="col form-group">
                                                        <label :for="'education.'+index+'.result'" class="form-label">Result<span class="text-danger">*</span></label>
                                                        <input type="text" v-model="e.result" :name="'education.'+index+'.result'"
                                                               class="form-control" :id="'education.'+index+'.result'" placeholder="Result">
                                                        <small class="invalid-feedback text-danger"></small>
                                                    </div>
                                                    <div class="col form-group">
                                                        <label :for="'education.'+index+'.passing_year'" class="form-label">Passing Year<span class="text-danger">*</span></label>
                                                        <input type="text" v-model="e.passing_year" :name="'education.'+index+'.passing_year'"
                                                               class="form-control" :id="'education.'+index+'.passing_year'" placeholder="Passing Year">
                                                        <small class="invalid-feedback text-danger"></small>
                                                    </div>
                                                    <div class="col form-group">
                                                        <div class="d-flex">
                                                            <div class="me-2 form-group w-100">
                                                                <label :for="'education.'+index+'.achievement'" class="form-label">Achievement</label>
                                                                <input type="text" v-model="e.achievement" :name="'education.'+index+'.achievement'"
                                                                       class="form-control" :id="'education.'+index+'.achievement'" placeholder="Achievement">
                                                                <small class="invalid-feedback text-danger"></small>
                                                            </div>
                                                            <div @click="splice(index, addEditParam.education)" v-if="index > 0">
                                                                <i class="bx bx-trash text-danger cursor-pointer item-delete"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-end">
                                                    <button type="button" @click="addNew" class="btn btn-inverse-primary">Add more</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-end">
                            <router-link :to="{name: 'member'}" type="button" class="btn btn-secondary me-2 btn-width" >Close</router-link>
                            <button type="submit" class="btn btn-primary btn-width" v-if="!loading">
                                Save
                            </button>
                            <button type="button" class="btn btn-primary btn-width" v-if="loading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from "../Common/Breadcrumb";
import ApiService from "../../Services/ApiService";
import ApiRoutes from "../../Services/ApiRoutes";
export default {
    components: {Breadcrumb},
    data() {
        return {
            breadCrumb: ['Edit'],
            loading: false,
            dataLoading: false,
            addEditParam: {},
            id: null,
            photo: null,
            signature: null,
            departments: [],
            designations: []
        }
    },
    created() {
        this.id = this.$route.params.id;
        this.getSingle()
    },
    mounted () {

    },
    watch: {},
    methods: {
        addNew: function () {
            this.addEditParam.education.push({
                id: '',
                exam_name: '',
                group: '',
                board: '',
                result: '',
                passing_year: '',
                achievement: '',
            })
        },
        getNextId: function () {
            ApiService.POST(ApiRoutes.LastIdMember, {type: this.addEditParam.type}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.addEditParam.id_number = res.data
                }
            });
        },
        departmentList: function () {
            ApiService.POST(ApiRoutes.ListDepartment, {limit: 5000, page: 1}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.departments = res.data.data
                }
            });
        },
        designationList: function () {
            ApiService.POST(ApiRoutes.ListDesignation, {limit: 5000, page: 1}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.designations = res.data.data
                }
            });
        },
        addPhoto: function (e) {
            this.addEditParam.image = e.target.files[0];
            this.photo = URL.createObjectURL(this.addEditParam.image);
        },
        addSignature: function (e) {
            this.addEditParam.signature = e.target.files[0];
            this.signature = URL.createObjectURL(this.addEditParam.signature);
        },
        removePhoto: function () {
            this.addEditParam.image = '';
            this.photo = null;
        },
        removeSignature: function () {
            this.addEditParam.signature = '';
            this.signature = null;
        },
        manage: function () {
            ApiService.ClearErrorHandler();
            this.loading = true;
            let param = this.makeFormData(this.addEditParam)
            ApiService.POST(ApiRoutes.EditMember, param, (res) => {
                this.loading = false;
                if (parseInt(res.status) === 200) {
                    this.$router.push({
                        name: 'member'
                    })
                } else {
                    ApiService.ErrorHandler(res.errors);
                }
            });
        },
        getSingle: function () {
            this.dataLoading = true;
            ApiService.POST(ApiRoutes.SingleMember, {id: this.id}, (res) => {
                this.dataLoading = false;
                if (parseInt(res.status) === 200) {
                    this.addEditParam = res.data
                    if (this.addEditParam.image) {
                        this.photo = this.addEditParam.image_path
                    }
                    if (this.addEditParam.signature) {
                        this.signature = this.addEditParam.signature_path
                    }
                }
            });
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <div>
        <Breadcrumb section-name="Accounts" :breadcrumb="breadCrumb"></Breadcrumb>
        <div class="col-12">
            <div class="card mb-0">
                <div class="card-body" :class="{'data-loading': dataLoading}">
                    <form @submit.prevent="manage">
                        <div class="fixed-card-height">
                            <div class="card border-top border-0 border-4 border-primary">
                                <div class="card-body p-4">
                                    <div class="card-title d-flex align-items-center">
                                        <h5 class="mb-0 text-primary">Accounts Expenditure Information</h5>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-6 mb-3 form-group">
                                            <label class="form-label">Date<span class="text-danger">*</span></label>
                                            <flat-pickr class="form-control" v-model="addEditParam.date" name="date"
                                                        :config="{dateFormat: 'Y-m-d', altInput: true, altFormat:'d/m/Y'}"/>
                                            <small class="invalid-feedback text-danger"></small>
                                        </div>
                                        <div class="col-sm-6 mb-3 form-group">
                                            <label class="form-label">Accounts Category</label>
                                            <select class="form-select" v-model="addEditParam.category_id" name="category_id">
                                                <option v-for="c in categories" :value="c.id">{{ c.name }}</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-6 mb-3 form-group">
                                            <label class="form-label">Expense By<span class="text-danger">*</span></label>
                                            <multiselect name="member_id" v-model="member"  track-by="id" placeholder="Select User"
                                                         label="name" :options="members" :multiple="false">
                                            </multiselect>
                                            <small class="invalid-feedback text-danger"></small>
                                        </div>
                                        <div class="col-sm-6 mb-3 form-group">
                                            <label class="form-label">Payment Method<span class="text-danger">*</span></label>
                                            <select class="form-select" v-model="addEditParam.payment_method" name="payment_method">
                                                <option value="bank">Bank</option>
                                                <option value="bkash">Bkash</option>
                                                <option value="nagad">Nagad</option>
                                                <option value="others">Others</option>
                                            </select>
                                            <small class="invalid-feedback text-danger"></small>
                                        </div>
                                        <div class="col-sm-6 mb-3 form-group" v-if="addEditParam.payment_method == 'bank'">
                                            <label class="form-label">Bank Name</label>
                                            <select class="form-select" v-model="addEditParam.bank_id" name="bank_id">
                                                <option v-for="b in banks" :value="b.id">{{ b.name }}</option>
                                            </select>
                                            <small class="invalid-feedback text-danger"></small>
                                        </div>
                                        <div class="col-md-6 mb-3 form-group" v-if="addEditParam.payment_method == 'bank'">
                                            <label class="form-label">Bank Account Number</label>
                                            <input type="text" class="form-control" name="account_number" v-model="addEditParam.account_number">
                                            <small class="invalid-feedback text-danger"></small>
                                        </div>

                                        <div class="col-sm-6 mb-3 form-group">
                                            <label class="form-label">Accounts Head</label>
                                            <select class="form-select" v-model="addEditParam.account_head_id" name="account_head_id">
                                                <option v-for="a in accountHeads" :value="a.id">{{ a.name }}</option>
                                            </select>
                                            <small class="invalid-feedback text-danger"></small>
                                        </div>

                                        <div class="col-md-6 form-group">
                                            <label class="form-label">Amount<span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" name="amount" v-model="addEditParam.amount">
                                            <small class="invalid-feedback text-danger"></small>
                                        </div>
                                        <div class="col-sm-6 mb-3 form-group">
                                            <label class="form-label">Approved By</label>
                                            <multiselect  name="approved_by" v-model="approve_by" track-by="id" placeholder="Select User"
                                                          label="name"  :options="members" :multiple="true"></multiselect>
                                            <small class="invalid-feedback text-danger"></small>
                                        </div>
                                        <div class="col-md-6 mb-3 form-group">
                                            <label class="form-label">Remarks</label>
                                            <input type="text" class="form-control" name="remarks" v-model="addEditParam.remarks">
                                            <small class="invalid-feedback text-danger"></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-end">
                            <router-link :to="{name: 'expense'}" type="button" class="btn btn-secondary me-2 btn-width" >Close</router-link>
                            <button type="submit" class="btn btn-primary btn-width" v-if="!loading">
                                Save
                            </button>
                            <button type="button" class="btn btn-primary btn-width" v-if="loading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from "../../Common/Breadcrumb";
import ApiService from "../../../Services/ApiService";
import ApiRoutes from "../../../Services/ApiRoutes";
export default {
    components: {Breadcrumb},
    data() {
        return {
            breadCrumb: ['Expenditure', 'Edit'],
            loading: false,
            dataLoading: false,
            addEditParam: {},
            id: null,
            members: [],
            categories: [],
            accountHeads: [],
            banks: [],
            member: null,
            approve_by: [],
        }
    },
    created() {
        this.id = this.$route.params.id;
        this.memberList()
        this.categoryList()
        this.accountList()
        this.bankList()

    },
    mounted () {
        this.getSingle()
    },
    watch: {},
    methods: {
        manage: function () {
            ApiService.ClearErrorHandler();
            this.loading = true;
            this.addEditParam.approved_by = []
            if (this.approve_by.length > 0) {
                this.approve_by.map(v => {
                    this.addEditParam.approved_by.push(v.id)
                })
            }
            if (this.member) {
                this.addEditParam.member_id = this.member.id
            }
            ApiService.POST(ApiRoutes.EditExpense, this.addEditParam, (res) => {
                this.loading = false;
                if (parseInt(res.status) === 200) {
                    this.$router.push({
                        name: 'expense'
                    })
                } else {
                    ApiService.ErrorHandler(res.errors);
                }
            });
        },
        updateMember: function (member) {
            this.addEditParam.member_id = member.id
        },
        memberList: function () {
            ApiService.POST(ApiRoutes.ListMember, {limit: 5000, page: 1}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.members = res.data.data
                }
            });
        },
        categoryList: function () {
            ApiService.POST(ApiRoutes.ListCategory, {limit: 5000, page: 1}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.categories = res.data.data
                }
            });
        },
        accountList: function () {
            ApiService.POST(ApiRoutes.ListAccountHead, {limit: 5000, page: 1}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.accountHeads = res.data.data
                }
            });
        },
        bankList: function () {
            ApiService.POST(ApiRoutes.ListBank, {limit: 5000, page: 1}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.banks = res.data.data
                }
            });
        },
        getSingle: function () {
            this.dataLoading = true;
            ApiService.POST(ApiRoutes.SingleExpense, {id: this.id}, (res) => {
                this.dataLoading = false;
                if (parseInt(res.status) === 200) {
                    this.addEditParam = res.data

                    if (this.members.length > 0) {
                        this.members.map(v => {
                            if (res.data.approved_by.length > 0) {
                                if (res.data.approved_by.indexOf(v.id) > -1) {
                                    this.approve_by.push(v)
                                }
                            }
                            if (res.data.member_id) {
                                if (v.id == res.data.member_id) {
                                    this.member = v
                                }
                            }
                        })

                    }
                }
            });
        }
    }
}
</script>

<style scoped>

</style>

const ApiVersion = '/api/v1.0'
const ApiRoutes = {
    // Authentication
    Login: ApiVersion + '/auth/login',
    //Department
    AddDepartment: ApiVersion + '/department/save',
    EditDepartment: ApiVersion + '/department/update',
    SingleDepartment: ApiVersion + '/department/single',
    DeleteDepartment: ApiVersion + '/department/delete',
    ListDepartment: ApiVersion + '/department/list',
    //Designation
    AddDesignation: ApiVersion + '/designation/save',
    EditDesignation: ApiVersion + '/designation/update',
    SingleDesignation: ApiVersion + '/designation/single',
    DeleteDesignation: ApiVersion + '/designation/delete',
    ListDesignation: ApiVersion + '/designation/list',
    //Category
    AddCategory: ApiVersion + '/category/save',
    EditCategory: ApiVersion + '/category/update',
    SingleCategory: ApiVersion + '/category/single',
    DeleteCategory: ApiVersion + '/category/delete',
    ListCategory: ApiVersion + '/category/list',
    //Bank
    AddBank: ApiVersion + '/bank/save',
    EditBank: ApiVersion + '/bank/update',
    SingleBank: ApiVersion + '/bank/single',
    DeleteBank: ApiVersion + '/bank/delete',
    ListBank: ApiVersion + '/bank/list',
    //Account Head
    AddAccountHead: ApiVersion + '/account/head/save',
    EditAccountHead: ApiVersion + '/account/head/update',
    SingleAccountHead: ApiVersion + '/account/head/single',
    DeleteAccountHead: ApiVersion + '/account/head/delete',
    ListAccountHead: ApiVersion + '/account/head/list',
    //Member
    AddMember: ApiVersion + '/member/save',
    EditMember: ApiVersion + '/member/update',
    SingleMember: ApiVersion + '/member/single',
    DeleteMember: ApiVersion + '/member/delete',
    ListMember: ApiVersion + '/member/list',
    LastIdMember: ApiVersion + '/member/last/id',
    autocompleteMember: ApiVersion + '/member/autocomplete',
    //Report
    DateWiseCollection: ApiVersion + '/report/dateWiseCollection',
    DateWiseExpense: ApiVersion + '/report/dateWiseExpense',
    DateWiseCollectionVsExpense: ApiVersion + '/report/dateWiseCollectionVsExpense',
    //Income
    AddIncome: ApiVersion + '/income/save',
    EditIncome: ApiVersion + '/income/update',
    SingleIncome: ApiVersion + '/income/single',
    DeleteIncome: ApiVersion + '/income/delete',
    ListIncome: ApiVersion + '/income/list',
    //Expense
    AddExpense: ApiVersion + '/expense/save',
    EditExpense: ApiVersion + '/expense/update',
    SingleExpense: ApiVersion + '/expense/single',
    DeleteExpense: ApiVersion + '/expense/delete',
    ListExpense: ApiVersion + '/expense/list',
};

export default ApiRoutes;

<template>
    <div>
        <Breadcrumb section-name="Member" :breadcrumb="breadCrumb"></Breadcrumb>
        <div class="col-12">
            <div class="card mb-0">
                <div class="card-body" :class="{'data-loading': dataLoading}">
                    <div class="fixed-card-height">
                        <div class="mt-2 mb-3">
                            <div class="row">
                                <div class="col-sm-4">
                                    <label>Search by ID Number or Name:</label>
                                    <div class="autocomplete-input">
                                        <input type="text" class="form-control" v-model="SearchParam.keyword" autocomplete="new-phone" @input="searchMember">
                                        <span v-if="searchLoading" class="input-loader spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <i v-if="addEditParam" class="input-loader cursor-pointer bx bx-x fs-4" style="top: 0.1rem" @click="removeMember"></i>
                                        <div class="input-result" v-if="members.length > 0">
                                            <div class="each-box" v-for="member in members" @click="selectMember(member)">
                                                 <div class="d-flex align-items-center">
                                                     <img class="me-2" style="border-radius: 50%; height: 25px; width: 25px" :src="member.image_path" alt="">
                                                     {{ member.name }} - {{member.id_number}}
                                                 </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="addEditParam">
                            <div class="col-md-12 form-group d-flex align-items-center mb-3">
                                <template v-if="photo">
                                    <div class="position-relative w-content">
                                        <img class="avatar" :src="photo" alt="">
                                    </div>
                                </template>
                            </div>

                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Member Type:</label>
                                <div class="text-capitalize">{{ addEditParam.type }}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">ID Number:</label>
                                <div>{{addEditParam.id_number}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">RI Member No:</label>
                                <div>{{addEditParam.ri_member_no}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Category:</label>
                                <div class="text-capitalize">{{addEditParam.category}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center ">
                                <label class="form-label">Contact Number:</label>
                                <div>{{addEditParam.contact_number}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Emergency Number:</label>
                                <div>{{addEditParam.emergency_contact}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Whatsapp Number:</label>
                                <div>{{addEditParam.whatsapp_number}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Email:</label>
                                <div>{{addEditParam.email_address}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Facebook Link:</label>
                                <div>{{addEditParam.facebook_link}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Instagram Link:</label>
                                <div>{{addEditParam.instagram_link}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Present Address:</label>
                                <div>{{addEditParam.present_address}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Permanent Address:</label>
                                <div>{{addEditParam.permanent_address}}</div>
                            </div>
                            <div class="col-sm-4">
                                <div class="mb-3 form-group d-flex align-items-center">
                                    <label class="form-label">Name:</label>
                                    <div>{{addEditParam.name}}</div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="mb-3 form-group d-flex align-items-center">
                                    <label class="form-label">Father's Name:</label>
                                    <div>{{addEditParam.father_name}}</div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="mb-3 form-group d-flex align-items-center">
                                    <label class="form-label">Mother's Name:</label>
                                    {{addEditParam.mother_name}}
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="mb-3 form-group d-flex align-items-center">
                                    <label class="form-label">Spouse Name:</label>
                                    <div>{{addEditParam.spouse_name}}</div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="mb-3 form-group d-flex align-items-center">
                                    <label class="form-label">Number of Child:</label>
                                    <div>{{addEditParam.no_of_child}}</div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="mb-3 form-group d-flex align-items-center">
                                    <label class="form-label">Number of Son:</label>
                                    <div>{{addEditParam.no_of_son}}</div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="mb-3 form-group d-flex align-items-center">
                                    <label class="form-label">Number of Daughter:</label>
                                    <div>{{addEditParam.no_of_daughter}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Nationality:</label>
                                <div>{{addEditParam.nationality}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">NID Number:</label>
                                <div>{{addEditParam.nid_number}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Gender:</label>
                                <div class="text-capitalize">{{addEditParam.gender}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Religion:</label>
                                <div class="text-capitalize">{{addEditParam.religion}}</div>
                            </div>

                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Marital Status:</label>
                                <div class="text-capitalize">{{addEditParam.marital_status}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Blood Group:</label>
                                <div class="text-capitalize">{{addEditParam.blood_group}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Hobby:</label>
                                <div>{{addEditParam.hobby}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center position-relative">
                                <label class="form-label">Date of Birth:</label>
                                <div>{{addEditParam.format_date_of_birth}}</div>
                            </div>

                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Profession:</label>
                                <div>{{addEditParam.profession}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Organization:</label>
                                <div>{{addEditParam.organization}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Last Education:</label>
                                <div>{{addEditParam.last_education}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Remuneration:</label>
                                <div>{{addEditParam.remuneration}}</div>
                            </div>

                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Member:</label>
                                <div class="text-capitalize">{{addEditParam.member}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Department:</label>
                                <div>{{addEditParam.department_name}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Designation:</label>
                                <div>{{addEditParam.designation_name}}</div>
                            </div>

                            <div class="col-md-4 mb-3 form-group d-flex align-items-center position-relative">
                                <label class="form-label">Joining Date:</label>
                                <div>{{addEditParam.format_joining_date}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Reference:</label>
                                <div>{{addEditParam.references}}</div>
                            </div>
                            <div class="col-md-4 mb-3 form-group d-flex align-items-center">
                                <label class="form-label">Other Organization:</label>
                                <div>{{addEditParam.others_organization}}</div>
                            </div>
                            <div class="col-sm-4">
                                <template v-if="signature">
                                    <div class="position-relative">
                                        <label class="form-label">Signature:</label>
                                        <img class="signature" :src="signature" alt="">
                                    </div>
                                </template>
                            </div>
                            <div class="col-sm-12">
                                <table class="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>Exam Name</th>
                                        <th>Group/Subject</th>
                                        <th>Board/University</th>
                                        <th>Result</th>
                                        <th>Passing Year</th>
                                        <th>Achievement</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr  v-for="(e, index) of addEditParam.education">
                                        <td>
                                            <div class="text-capitalize">{{e.exam_name}}</div>
                                        </td>
                                        <td>
                                            <div>{{e.group}}</div>
                                        </td>
                                        <td>
                                            <div>{{e.board}}</div>
                                        </td>
                                        <td>
                                            <div>{{e.result}}</div>
                                        </td>
                                        <td>
                                            <div>{{e.passing_year}}</div>
                                        </td>
                                        <td>
                                            <div>{{e.achievement}}</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="text-end">
                        <router-link :to="{name: 'member'}" type="button" class="btn btn-secondary me-2 btn-width" >Close</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from "../Common/Breadcrumb";
import ApiService from "../../Services/ApiService";
import ApiRoutes from "../../Services/ApiRoutes";
export default {
    components: {Breadcrumb},
    data() {
        return {
            breadCrumb: ['View'],
            loading: false,
            dataLoading: false,
            addEditParam: null,
            photo: null,
            signature: null,
            searchLoading: false,
            SearchParam: {
                keyword: ''
            },
            members: []
        }
    },
    created() {

    },
    mounted () {

    },
    watch: {},
    methods: {
        selectMember: function (member) {
            this.SearchParam.keyword = member.name
            this.members = []
            this.getSingle(member.id)
        },
        removeMember: function () {
            this.members = []
            this.SearchParam.keyword = ''
            this.addEditParam = null
        },
        searchMember: function () {
            this.searchLoading = true
            ApiService.POST(ApiRoutes.autocompleteMember, this.SearchParam, (res) => {
                this.searchLoading = false;
                if (parseInt(res.status) === 200) {
                    this.members = res.data
                }
            });
        },
        getSingle: function (id) {
            this.dataLoading = true;
            ApiService.POST(ApiRoutes.SingleMember, {id: id}, (res) => {
                this.dataLoading = false;
                if (parseInt(res.status) === 200) {
                    this.addEditParam = res.data
                    if (this.addEditParam.image_path) {
                        this.photo = this.addEditParam.image_path
                    }
                    if (this.addEditParam.signature_path) {
                        this.signature = this.addEditParam.signature_path
                    }
                }
            });
        }
    }
}
</script>

<style scoped>
.form-label{
    font-weight: bold;
    margin-right: 15px;
    margin-bottom: 0;
}
</style>

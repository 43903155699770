<template>
    <div>
        <Breadcrumb section-name="Accounts" :breadcrumb="breadCrumb" :btn-name="'Add '+ breadCrumb"
                    :click-action="addExpense"></Breadcrumb>
        <div class="col-12">
            <div class="card mb-0">
                <div class="card-body">
                    <Table :tbl-data="table" :param="param"></Table>
                </div>
            </div>
        </div>
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    </div>
</template>

<script>
import Breadcrumb from "../../Common/Breadcrumb";
import ApiService from "../../../Services/ApiService";
import ApiRoutes from "../../../Services/ApiRoutes";
import Table from "../../Common/Table";
import ConfirmDialogue from "../../Common/confirmPopup/ConfirmDialogue";
export default {
    components: {Table, Breadcrumb, ConfirmDialogue},
    data() {
        return {
            breadCrumb: [],
            param: {
                keyword: '',
                limit: 10,
                order_by: 'id',
                order_mode: 'DESC',
                page: 1,
                member_id: '',
                category_id: '',
                account_head_id: '',
                bank_id: '',
                payment_method: '',
                start_date: '',
                end_date: '',
            },
            table: {
                columns: [
                    {type: 'text', key: 'date', label: 'Date', sortable: true},
                    {type: 'text', key: 'deposit_by', label: 'Expense By', sortable: true},
                    {type: 'text', key: 'category_name', label: 'Accounts Category', sortable: true, isCapitalize: true},
                    {type: 'text', key: 'account_head', label: 'Accounts Head', photo: true, sortable: true, isCapitalize: true},
                    {type: 'text', key: 'payment_method', label: 'Payment Method', sortable: true},
                    {type: 'text', key: 'amount', label: 'Amount', sortable: true},
                    {type: 'text', key: 'bank_name', label: 'Bank Name', sortable: true},
                    {type: 'text', key: 'account_number', label: 'Bank Account Number', sortable: true},
                ],
                rows: [],
                row_actions: [
                    {name: 'edit', type: 'action', label: 'bx bx-pencil', color: 'btn-info'},
                    {name: 'delete', type: 'action', label: 'bx bx-trash', color: 'btn-gradient-danger'}
                ],
                tableIconAction: (data) => {
                    this.tableIconAction(data.row_action, data.row_data);
                },
                tableLoading: false,
                paginateData: {},
                updatePagination: (page) => {
                    this.list(page)
                },
                updateFilter: (param) => {
                    this.param = param
                    this.list()
                },
                dateSearch: {
                    selectedDate: '',
                    action: (event) => {
                        if (event) {
                            let dateArr = event.split('to')
                            this.param.start_date = dateArr[0]
                            this.param.end_date = dateArr[1]
                            this.list()
                        }
                    }
                },
                dropdown: [
                    {
                        title: 'Deposit By',
                        data: [],
                        action: (event) => {
                            this.param.member_id = event.target.value
                            this.list()
                        }
                    },
                    {
                        title: 'Accounts Category',
                        data: [],
                        action: (event) => {
                            this.param.category_id = event.target.value
                            this.list()
                        }
                    },
                    {
                        title: 'Accounts Head',
                        data: [],
                        action: (event) => {
                            this.param.account_head_id = event.target.value
                            this.list()
                        }
                    },
                    {
                        title: 'Bank Name',
                        data: [],
                        action: (event) => {
                            this.param.bank_id = event.target.value
                            this.list()
                        }
                    },
                    {
                        title: 'Payment Method',
                        data: [
                            {id: 'bank', name: 'Bank'},
                            {id: 'bkash', name: 'Bkash'},
                            {id: 'nagad', name: 'Nagad'},
                            {id: 'others', name: 'Others'}
                        ],
                        action: (event) => {
                            this.param.payment_method = event.target.value
                            this.list()
                        }
                    },
                ],
                noDataError: ' Please click "+ Add Expense" to add a new Expense'
            },
            id: []
        }
    },
    methods: {
        memberList: function () {
            ApiService.POST(ApiRoutes.ListMember, {limit: 5000, page: 1}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.table.dropdown[0].data = res.data.data
                }
            });
        },
        categoryList: function () {
            ApiService.POST(ApiRoutes.ListCategory, {limit: 5000, page: 1}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.table.dropdown[1].data = res.data.data
                }
            });
        },
        accountList: function () {
            ApiService.POST(ApiRoutes.ListAccountHead, {limit: 5000, page: 1}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.table.dropdown[2].data = res.data.data
                }
            });
        },
        bankList: function () {
            ApiService.POST(ApiRoutes.ListBank, {limit: 5000, page: 1}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.table.dropdown[3].data = res.data.data
                }
            });
        },
        addExpense() {
            this.$router.push({
                name: 'expenseAdd'
            })
        },
        async tableIconAction (type, data) {
            this.id = this.structureReturnData(data)
            if (type === 'edit') {
                this.$router.push({
                    name: 'expenseEdit',
                    params: { id: data.id }
                })
            } else if (type === 'delete') {
                const ok = await this.$refs.confirmDialogue.show({
                    title: 'Delete Expense',
                    message: 'Are you sure you want to delete this Expense?',
                    okButton: 'Delete',
                })
                if (ok) {
                    this.delete()
                }
            }
        },

        list: function (page) {
            if (page == undefined) {
                page = 1
            }
            this.param.page = page;
            this.table.loading = true
            ApiService.POST(ApiRoutes.ListExpense, this.param, (res) => {
                this.table.loading = false
                if (parseInt(res.status) === 200) {
                    this.table.rows = res.data.data
                    this.table.paginateData = res.data
                }
            });
        },
        delete: function () {
            ApiService.POST(ApiRoutes.DeleteExpense, {id: this.id}, (res) => {
                this.$refs.confirmDialogue._loading(false)
                if (parseInt(res.status) === 200) {
                    this.$toast.success(res.message)
                    this.$refs.confirmDialogue._hide()
                    this.list()
                }
            });
        },
    },
    mounted() {
        this.list()
    },
    created() {
        this.memberList()
        this.categoryList()
        this.accountList()
        this.bankList()
        this.breadCrumb.push(this.$route.name)
    }
}
</script>

<style scoped>

</style>

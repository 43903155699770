<template>
    <div>
        <Breadcrumb section-name="Academic" :breadcrumb="breadCrumb" :btn-name="'Add '+ breadCrumb"
                    :click-action="addMember"></Breadcrumb>
        <div class="col-12">
            <div class="card mb-0">
                <div class="card-body">
                    <Table :tbl-data="table" :param="param"></Table>
                </div>
            </div>
        </div>
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    </div>
</template>

<script>
import Breadcrumb from "../Common/Breadcrumb";
import ApiService from "../../Services/ApiService";
import ApiRoutes from "../../Services/ApiRoutes";
import Table from "../Common/Table";
import DownloadService from "../../Services/DownloadService";
import ConfirmDialogue from "../Common/confirmPopup/ConfirmDialogue";
export default {
    components: {Table, Breadcrumb, ConfirmDialogue},
    data() {
        return {
            breadCrumb: [],
            param: {
                keyword: '',
                limit: 10,
                order_by: 'id',
                order_mode: 'DESC',
                page: 1,
            },
            table: {
                columns: [
                    {type: 'text', key: 'id_number', label: 'ID', sortable: true},
                    {type: 'text', key: 'ri_member_no', label: 'RI Member Number', sortable: true},
                    {type: 'text', key: 'name', label: 'Name', photo: true, sortable: true},
                    {type: 'text', key: 'contact_number', label: 'Contact Number', sortable: true},
                    {type: 'text', key: 'department_name', label: 'Department', sortable: true},
                    {type: 'text', key: 'designation_name', label: 'Designation', sortable: true},
                    {type: 'text', key: 'date_of_birth', label: 'Date of Birth', sortable: true},
                    {type: 'text', key: 'joining_date', label: 'Joining Date', sortable: true},
                ],
                rows: [],
                row_actions: [
                    {name: 'view', type: 'action', label: 'bx bx-search-alt', color: 'btn-gradient-primary'},
                    {name: 'edit', type: 'action', label: 'bx bx-pencil', color: 'btn-info'},
                    {name: 'delete', type: 'action', label: 'bx bx-trash', color: 'btn-gradient-danger'}
                ],
                tableIconAction: (data) => {
                    this.tableIconAction(data.row_action, data.row_data);
                },
                tableLoading: false,
                paginateData: {},
                updatePagination: (page) => {
                    this.list(page)
                },
                updateFilter: (param) => {
                    this.param = param
                    this.list()
                },
                dropdown: [
                    {
                        title: 'All Department',
                        data: [],
                        action: (event) => {
                            this.param.department_id = event.target.value
                            this.list()
                        }
                    },
                    {
                        title: 'All Designation',
                        data: [],
                        action: (event) => {
                            this.param.designation_id = event.target.value
                            this.list()
                        }
                    },
                    {
                        title: 'All Blood Group',
                        data: [
                            {id: 'a+', name: 'A+'},
                            {id: 'a-', name: 'A-'},
                            {id: 'b+', name: 'B+'},
                            {id: 'b-', name: 'B-'},
                            {id: 'ab+', name: 'AB+'},
                            {id: 'ab-', name: 'AB-'},
                            {id: 'o+', name: 'O+'},
                            {id: 'o-', name: 'O-'},
                        ],
                        action: (event) => {
                            this.param.blood_group = event.target.value
                            this.list()
                        }
                    },
                ],
                noDataError: ' Please click "+ Add Member" to add a new Member'
            },
            id: []
        }
    },
    methods: {
        departmentList: function () {
            ApiService.POST(ApiRoutes.ListDepartment, {limit: 5000, page: 1}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.table.dropdown[0].data = res.data.data
                }
            });
        },
        designationList: function () {
            ApiService.POST(ApiRoutes.ListDesignation, {limit: 5000, page: 1}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.table.dropdown[1].data = res.data.data
                }
            });
        },
        addMember() {
            this.$router.push({
                name: 'memberAdd'
            })
        },
        async tableIconAction (type, data) {
            this.id = this.structureReturnData(data)
            if (type === 'edit') {
                this.$router.push({
                    name: 'memberEdit',
                    params: { id: data.id }
                })
            } else if (type === 'view') {
                this.$router.push({
                    name: 'memberView',
                    params: { id: data.id }
                })
            } else if (type === 'delete') {
                const ok = await this.$refs.confirmDialogue.show({
                    title: 'Delete Member',
                    message: 'Are you sure you want to delete this Member?',
                    okButton: 'Delete',
                })
                if (ok) {
                    this.delete()
                }
            }
        },

        list: function (page) {
            if (page == undefined) {
                page = 1
            }
            this.param.page = page;
            this.table.loading = true
            ApiService.POST(ApiRoutes.ListMember, this.param, (res) => {
                this.table.loading = false
                if (parseInt(res.status) === 200) {
                    this.table.rows = res.data.data
                    this.table.paginateData = res.data
                }
            });
        },
        delete: function () {
            ApiService.POST(ApiRoutes.DeleteMember, {id: this.id}, (res) => {
                this.$refs.confirmDialogue._loading(false)
                if (parseInt(res.status) === 200) {
                    this.$toast.success(res.message)
                    this.$refs.confirmDialogue._hide()
                    this.list()
                }
            });
        },
    },
    mounted() {
        this.list()
    },
    created() {
        this.departmentList()
        this.designationList()
        this.breadCrumb.push(this.$route.name)
    }
}
</script>

<style scoped>

</style>

<template>
    <div class="sidebar-wrapper" data-simplebar="true">
        <div class="sidebar-header">
            <div>
                <h4 class="logo-text">Samity Name</h4>
            </div>
            <div class="toggle-icon ms-auto" @click="isToggled = !isToggled"><i class='bx' :class="toggleClass()"></i></div>
        </div>
        <!--navigation-->
        <ul class="metismenu" id="menu">
            <li>
                <router-link :to="{name: 'dashboard'}">
                    <div class="parent-icon"><i class='bx bx-home-circle'></i>
                    </div>
                    <div class="menu-title">Dashboard</div>
                </router-link>
            </li>
            <li>
                <a href="javascript:void(0);" class="has-arrow">
                    <div class="parent-icon"><i class="bx bx-cog"></i>
                    </div>
                    <div class="menu-title">Settings</div>
                </a>
                <ul>
                    <li :class="isActive('department')">
                        <router-link :to="{name: 'department'}"><i class="bx bx-right-arrow-alt"></i>Department</router-link>
                    </li>
                    <li :class="isActive('designation')">
                        <router-link :to="{name: 'designation'}"><i class="bx bx-right-arrow-alt"></i>Designation</router-link>
                    </li>
                    <li :class="isActive('category')">
                        <router-link :to="{name: 'category'}"><i class="bx bx-right-arrow-alt"></i>Accounts Category</router-link>
                    </li>
                    <li :class="isActive('bank')">
                        <router-link :to="{name: 'bank'}"><i class="bx bx-right-arrow-alt"></i>Bank Name</router-link>
                    </li>
                    <li :class="isActive('account')">
                        <router-link :to="{name: 'account'}"><i class="bx bx-right-arrow-alt"></i>Accounts Head</router-link>
                    </li>
                </ul>
            </li>
            <li>
                <router-link :to="{name: 'member'}">
                    <div class="parent-icon"><i class='bx bx-user'></i>
                    </div>
                    <div class="menu-title">Members</div>
                </router-link>
            </li>
            <li>
                <router-link :to="{name: 'memberViewSearch'}">
                    <div class="parent-icon"><i class='bx bx-user-circle'></i>
                    </div>
                    <div class="menu-title">Search Member</div>
                </router-link>
            </li>
            <li>
                <a href="javascript:void(0);" class="has-arrow">
                    <div class="parent-icon"><i class="bx bx-calculator"></i>
                    </div>
                    <div class="menu-title">Accounts</div>
                </a>
                <ul>
                    <li :class="isActive('income')">
                        <router-link :to="{name: 'income'}"><i class="bx bx-right-arrow-alt"></i>Income</router-link>
                    </li>
                    <li :class="isActive('expense')">
                        <router-link :to="{name: 'expense'}"><i class="bx bx-right-arrow-alt"></i>Expenditure</router-link>
                    </li>
                </ul>
            </li>
            <li>
                <a href="javascript:void(0);" class="has-arrow">
                    <div class="parent-icon"><i class="bx bx-bar-chart-alt-2"></i>
                    </div>
                    <div class="menu-title">Report</div>
                </a>
                <ul>
                    <li :class="isActive('dateWiseCollection')">
                        <router-link :to="{name: 'dateWiseCollection'}"><i class="bx bx-right-arrow-alt"></i>Date Wise Collection</router-link>
                    </li>
                    <li :class="isActive('dateWiseExpense')">
                        <router-link :to="{name: 'dateWiseExpense'}"><i class="bx bx-right-arrow-alt"></i>Date Wise Expense</router-link>
                    </li>
                    <li :class="isActive('dateWiseCollectionVsExpense')">
                        <router-link :to="{name: 'dateWiseCollectionVsExpense'}"><i class="bx bx-right-arrow-alt"></i>Date Wise Collection vs Expense</router-link>
                    </li>
                </ul>
            </li>
        </ul>
        <!--end navigation-->
    </div>
</template>

<script>
import CategoryService from "../../Services/CategoryService";
export default {
    data: function () {
        return {
            isToggled: false,
            categoryTypes: CategoryService
        }
    },
    methods: {
        toggleClass: function () {
            if (this.isToggled) {
                return 'bx-arrow-to-right'
            } else {
                return 'bx-arrow-to-left'
            }
        },
        isActive: function (routeName) {
            if (this.$route.name == routeName) {
                return 'mm-active'
            } else {
                return ''
            }
        }
    },
}
</script>

<style scoped>

</style>

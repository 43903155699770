<template>
    <div>
        <Breadcrumb section-name="Report" :breadcrumb="breadCrumb"></Breadcrumb>
        <div class="col-12">
            <div class="card mb-0">
                <div class="card-body">
                    <Table :tbl-data="table" :param="param"></Table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from "../Common/Breadcrumb";
import ApiService from "../../Services/ApiService";
import ApiRoutes from "../../Services/ApiRoutes";
import Table from "../Common/Table";
import ConfirmDialogue from "../Common/confirmPopup/ConfirmDialogue";
import DownloadService from "../../Services/DownloadService";
import dayjs from "dayjs";

export default {
    components: {Table, Breadcrumb, ConfirmDialogue},
    data() {
        return {
            breadCrumb: [],
            loading: false,
            addEditParam: {
                name: '',
            },
            param: {
                keyword: '',
                limit: 10,
                order_by: 'id',
                order_mode: 'DESC',
                page: 1,
                member_id: '',
                category_id: '',
                account_head_id: '',
                bank_id: '',
                payment_method: '',
                start_date: dayjs().startOf('month').format('YYYY-MM-DD'),
                end_date: dayjs().endOf('month').format('YYYY-MM-DD'),
            },
            actionType: 'Add',
            id: [],
            table: {
                columns: [
                    {type: 'text', key: 'date', label: 'Date', sortable: false},
                    {type: 'text', key: 'category_name', label: 'Category', sortable: false},
                    {type: 'text', key: 'amount', label: 'Amount', sortable: false},
                    {type: 'text', key: 'payment_method', label: 'Payment Method', sortable: false},
                ],
                rows: [],
                row_actions: [],
                dateSearch: {
                    selectedDate: dayjs().startOf('month').format('YYYY-MM-DD')+' to '+dayjs().endOf('month').format('YYYY-MM-DD'),
                    action: (event) => {
                        if (event.length > 1) {
                            this.param.start_date = dayjs(event[0]).format('YYYY-MM-DD')
                            this.param.end_date = dayjs(event[1]).format('YYYY-MM-DD')
                            this.list()
                        }
                    }
                },
                dropdown: [
                    {
                        title: 'Deposit By',
                        data: [],
                        action: (event) => {
                            this.param.member_id = event.target.value
                            this.list()
                        }
                    },
                    {
                        title: 'Accounts Category',
                        data: [],
                        action: (event) => {
                            this.param.category_id = event.target.value
                            this.list()
                        }
                    },
                    {
                        title: 'Accounts Head',
                        data: [],
                        action: (event) => {
                            this.param.account_head_id = event.target.value
                            this.list()
                        }
                    },
                    {
                        title: 'Bank Name',
                        data: [],
                        action: (event) => {
                            this.param.bank_id = event.target.value
                            this.list()
                        }
                    },
                ],
                tableLoading: false,
                paginateData: {},
                noDataError: ''
            },
        }
    },
    methods: {
        memberList: function () {
            ApiService.POST(ApiRoutes.ListMember, {limit: 5000, page: 1}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.table.dropdown[0].data = res.data.data
                }
            });
        },
        categoryList: function () {
            ApiService.POST(ApiRoutes.ListCategory, {limit: 5000, page: 1}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.table.dropdown[1].data = res.data.data
                }
            });
        },
        accountList: function () {
            ApiService.POST(ApiRoutes.ListAccountHead, {limit: 5000, page: 1}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.table.dropdown[2].data = res.data.data
                }
            });
        },
        bankList: function () {
            ApiService.POST(ApiRoutes.ListBank, {limit: 5000, page: 1}, (res) => {
                if (parseInt(res.status) === 200) {
                    this.table.dropdown[3].data = res.data.data
                }
            });
        },
        list: function (page) {
            if (page == undefined) {
                page = 1
            }
            this.param.page = page;
            this.table.loading = true
            ApiService.POST(ApiRoutes.DateWiseExpense, this.param, (res) => {
                this.table.loading = false
                if (parseInt(res.status) === 200) {
                    this.table.rows = res.data.data;
                    this.table.paginateData = res.data
                }
            });
        },
    },
    mounted() {
        this.list()
    },
    created() {
        this.memberList()
        this.categoryList()
        this.accountList()
        this.bankList()
        this.breadCrumb.push('Date Wise Expense')
    }
}
</script>

<style scoped>

</style>
